html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

#welcome {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

#welcome {
    -webkit-text-size-adjust: none
}

mark {
    background-color: transparent;
    color: inherit
}

input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input[type="text"],
input[type="email"],
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

#welcome {
    line-height: 1.0;
    min-height: var(--viewport-height);
    min-width: 320px;
    overflow-x: hidden;
    word-wrap: break-word;
}

#welcome:before {
    background-attachment: scroll;
    content: '';
    display: block;
    height: var(--background-height);
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transform: scale(1);
    width: 100vw;
    z-index: 0;
    background-image: linear-gradient(45deg, #5994E4 31%, #D59BCB 67%, #8F88D8 100%);
    background-position: 0% 0%;
    background-repeat: repeat;
    background-size: auto;
}

:root {
    --background-height: 100vh;
    --site-language-alignment: left;
    --site-language-direction: ltr;
    --site-language-flex-alignment: flex-start;
    --site-language-indent-left: 1;
    --site-language-indent-right: 0;
    --site-language-margin-left: 0;
    --site-language-margin-right: auto;
    --viewport-height: 100vh;
}

html {
    font-size: 18pt;
}

u {
    text-decoration: underline;
}

strong {
    color: inherit;
    font-weight: bolder;
}

em {
    font-style: italic;
}

code {
    background-color: rgba(144, 144, 144, 0.25);
    border-radius: 0.25em;
    font-family: 'Lucida Console', 'Courier New', monospace;
    font-size: 0.9em;
    font-weight: normal;
    letter-spacing: 0;
    margin: 0 0.25em;
    padding: 0.25em 0.5em;
    text-indent: 0;
}

mark {
    background-color: rgba(144, 144, 144, 0.25);
}

spoiler-text {
    -webkit-text-stroke: 0;
    background-color: rgba(32, 32, 32, 0.75);
    text-shadow: none;
    text-stroke: 0;
    color: transparent;
    cursor: pointer;
    transition: color 0.1s ease-in-out;
}

spoiler-text.active {
    color: #FFFFFF;
    cursor: text;
}

s {
    text-decoration: line-through;
}

sub {
    font-size: smaller;
    vertical-align: sub;
}

sup {
    font-size: smaller;
    vertical-align: super;
}

a {
    color: inherit;
    text-decoration: underline;
    transition: color 0.25s ease;
}

a[onclick]:not([href]) {
    cursor: pointer;
}

deferred-script {
    display: none;
}

#wrapper {
    -webkit-overflow-scrolling: touch;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--viewport-height);
    overflow: hidden;
    position: relative;
    z-index: 2;
}

#main {
    --alignment: center;
    --flex-alignment: center;
    --indent-left: 1;
    --indent-right: 1;
    --margin-left: auto;
    --margin-right: auto;
    --border-radius-tl: 0;
    --border-radius-tr: 0;
    --border-radius-br: 0;
    --border-radius-bl: 0;
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    max-width: 100%;
    position: relative;
    text-align: var(--alignment);
    z-index: 1;
    transition: opacity 1s ease-in-out 0s;
}

#main>.inner {
    --padding-horizontal: 2rem;
    --padding-vertical: 2.5rem;
    --spacing: 1.5rem;
    --width: 48rem;
    border-radius: var(--border-radius-tl) var(--border-radius-tr) var(--border-radius-br) var(--border-radius-bl);
    max-width: 100%;
    position: relative;
    width: var(--width);
    z-index: 1;
    padding: var(--padding-vertical) var(--padding-horizontal);
}

#main>.inner>header {
    margin-bottom: var(--spacing);
}

#main>.inner>footer {
    margin-top: var(--spacing);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

#main>.inner>*>* {
    margin-top: var(--spacing);
    margin-bottom: var(--spacing);
}

#main>.inner>*> :first-child {
    margin-top: 0 !important;
}

#main>.inner>*> :last-child {
    margin-bottom: 0 !important;
}

#main>.inner>.full {
    margin-left: calc(var(--padding-horizontal) * -1);
    max-width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
    width: calc(100% + calc(var(--padding-horizontal) * 2) + 0.4725px);
}

#main>.inner>.full:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    margin-top: calc(var(--padding-vertical) * -1) !important;
}

#main>.inner>.full:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    margin-bottom: calc(var(--padding-vertical) * -1) !important;
}

#main>.inner>.full.screen {
    border-radius: 0 !important;
    max-width: 100vw;
    position: relative;
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
    right: auto;
}

#main>.inner>*>.full {
    margin-left: calc(-2rem);
    max-width: calc(100% + 4rem + 0.4725px);
    width: calc(100% + 4rem + 0.4725px);
}

#main>.inner>*>.full.screen {
    border-radius: 0 !important;
    max-width: 100vw;
    position: relative;
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
    right: auto;
}

#main>.inner>footer>.full:last-child {
    margin-bottom: -2.5rem !important;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

#main>.inner>.active>.full:first-child {
    margin-top: -2.5rem !important;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

#main>.inner>.active {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

#welcome.is-loading #main {
    opacity: 0;
}

#main>.inner>header,
#main>.inner>footer {
    transition: opacity 0.375s ease-in-out 0.5625s, visibility 0.375s linear 0.5625s;
}

#main>.inner>header.hidden,
#main>.inner>footer.hidden {
    transition: opacity 0.375s ease-in-out, visibility 0.375s;
    opacity: 0;
    visibility: hidden;
}

#main>.inner>section {
    transition: opacity 0.75s ease-in-out 0.375s, min-height 0.375s ease-in-out, max-height 0.375s ease-in-out;
}

#main>.inner>section.inactive {
    opacity: 0;
    transition: opacity 0.375s ease-in-out;
}

#welcome.is-instant #main,
#welcome.is-instant #main>.inner>*,
#welcome.is-instant #main>.inner>section>* {
    transition: none !important;
}

#welcome.is-instant:after {
    display: none !important;
    transition: none !important;
}

.image {
    display: block;
    line-height: 0;
    max-width: 100%;
    position: relative;
}

.image .frame {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    vertical-align: top;
    width: 100%;
}

.image .frame img {
    border-radius: 0 !important;
    max-width: 100%;
    vertical-align: top;
    width: inherit;
}

.image.full .frame {
    display: block;
}

.image.full:first-child .frame {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.image.full:last-child .frame {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

#image03 .frame {
    max-width: 100%;
    width: 206px;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    box-shadow: -0rem -0rem 0rem 0rem #FFFFFF;
    transition: none;
}

#image03 img {
    object-fit: cover;
    object-position: center;
}

#image03 .frame img {
    transition: none;
}

#image02 .frame {
    width: 11rem;
    height: 10.5rem;
    border-radius: 2rem;
    box-shadow: 0rem 1.75rem 2rem 0rem #E3C1C1;
    transition: none;
}

#image02 img {
    height: 100% !important;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
}

#image02 .frame img {
    transition: none;
}

#image01 .frame {
    width: 11rem;
    height: 10.875rem;
    border-radius: 2rem;
    box-shadow: 0rem 1.75rem 2rem 0rem #E3C1C1;
    transition: none;
}

#image01 img {
    height: 100% !important;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
}

#image01 .frame img {
    transition: none;
}

.gallery .inner {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
}

.gallery ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--flex-alignment);
}

.gallery ul li {
    position: relative;
}

.gallery ul li .thumbnail {
    border-radius: inherit;
    position: relative;
}

.gallery ul li .frame {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
}

.gallery ul li img {
    margin: 0;
    max-width: 100%;
    vertical-align: top;
    width: auto;
}

.gallery ul li .caption {
    width: 100%;
}

.gallery ul li .caption p {
    width: 100%;
}

.gallery ul li .caption a,
.gallery ul li .caption spoiler-text {
    pointer-events: auto;
}

#gallery01 .inner {
    max-width: 100%;
    width: 100%;
}

#gallery01 ul li {
    border-radius: 0rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
}

#gallery01 ul li .frame {
    border-radius: 0rem;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
}

#gallery01 ul {
    gap: 0.5rem;
}

#gallery01 ul li img {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    height: 8.875rem;
    object-fit: cover;
    object-position: center;
}

#gallery01 ul li .thumbnail {
    transition: none;
}

#gallery01 ul li .thumbnail img {
    transition: none;
}

#gallery01 ul li .n01 img {
    object-position: center;
}

#gallery01 ul li .n02 img {
    object-position: center;
}

#gallery01 ul li .n03 img {
    object-position: center;
}

h1,
h2,
h3,
p {
    direction: var(--site-language-direction);
    position: relative;
}

h1 span.p,
h2 span.p,
h3 span.p,
p span.p {
    display: block;
    position: relative;
}

h1 span[style],
h2 span[style],
h3 span[style],
p span[style],
h1 strong,
h2 strong,
h3 strong,
p strong,
h1 a,
h2 a,
h3 a,
p a,
h1 code,
h2 code,
h3 code,
p code,
h1 mark,
h2 mark,
h3 mark,
p mark,
h1 spoiler-text,
h2 spoiler-text,
h3 spoiler-text,
p spoiler-text {
    -webkit-text-fill-color: currentcolor;
}

#text04 {
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.25em;
    line-height: 1.625;
    font-weight: 400;
}

#text04 a {
    text-decoration: underline;
}

#text04 a:hover {
    text-decoration: none;
}

#text04 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text02 {
    text-align: center;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text02 a {
    text-decoration: underline;
}

#text02 a:hover {
    text-decoration: none;
}

#text02 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text07 {
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 0.325rem;
    width: calc(100% + 0.325rem);
    font-size: 1.5em;
    line-height: 1.375;
    font-weight: 400;
}

#text07 a {
    text-decoration: underline;
}

#text07 a:hover {
    text-decoration: none;
}

#text07 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text08 {
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text08 a {
    text-decoration: underline;
}

#text08 a:hover {
    text-decoration: none;
}

#text08 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text03 {
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 0.325rem;
    width: calc(100% + 0.325rem);
    font-size: 1.5em;
    line-height: 1.375;
    font-weight: 400;
}

#text03 a {
    text-decoration: underline;
}

#text03 a:hover {
    text-decoration: none;
}

#text03 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text05 {
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text05 a {
    text-decoration: underline;
}

#text05 a:hover {
    text-decoration: none;
}

#text05 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text09 {
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text09 a {
    text-decoration: underline;
}

#text09 a:hover {
    text-decoration: none;
}

#text09 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text10 {
    text-align: left;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text10 a {
    text-decoration: underline;
}

#text10 a:hover {
    text-decoration: none;
}

#text10 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text11 {
    text-align: left;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text11 a {
    text-decoration: underline;
}

#text11 a:hover {
    text-decoration: none;
}

#text11 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text12 {
    text-align: left;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text12 a {
    text-decoration: underline;
}

#text12 a:hover {
    text-decoration: none;
}

#text12 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text13 {
    text-align: left;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text13 a {
    text-decoration: underline;
}

#text13 a:hover {
    text-decoration: none;
}

#text13 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text14 {
    text-align: left;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text14 a {
    text-decoration: underline;
}

#text14 a:hover {
    text-decoration: none;
}

#text14 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text15 {
    text-align: left;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text15 a {
    text-decoration: underline;
}

#text15 a:hover {
    text-decoration: none;
}

#text15 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text16 {
    text-align: left;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text16 a {
    text-decoration: underline;
}

#text16 a:hover {
    text-decoration: none;
}

#text16 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#text06 {
    text-align: left;
    color: #FFFFFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    line-height: 1.625;
    font-weight: 400;
}

#text06 a {
    text-decoration: underline;
}

#text06 a:hover {
    text-decoration: none;
}

#text06 span.p:nth-child(n + 2) {
    margin-top: 1rem;
}

#footer {
    margin-top: 0rem !important;
}

hr {
    align-items: center;
    border: 0;
    display: flex;
    justify-content: var(--flex-alignment);
    min-height: 1rem;
    padding: 0;
    position: relative;
    width: 100%;
}

hr:before {
    content: '';
}

#divider01:not(:first-child) {
    margin-top: 0.5rem !important;
}

#divider01:not(:last-child) {
    margin-bottom: 0.5rem !important;
}

#divider01:before {
    width: 7rem;
    background-color: rgba(255, 255, 255, 0.169);
    height: 1px;
    border-radius: 0px;
}

.buttons {
    cursor: default;
    display: flex;
    justify-content: var(--flex-alignment);
    letter-spacing: 0;
    padding: 0;
}

.buttons li {
    max-width: 100%;
    text-align: var(--alignment);
}

.buttons li a {
    align-items: center;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
}

#buttons02 {
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
}

#buttons02:not(:first-child) {
    margin-top: 2rem !important;
}

#buttons02:not(:last-child) {
    margin-bottom: 2rem !important;
}

#buttons02 li a {
    display: inline-flex;
    width: 24.875rem;
    height: 2.625rem;
    line-height: 2.625rem;
    padding: 0 1.3125rem;
    vertical-align: middle;
    text-transform: uppercase;
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 0.5rem;
    padding-left: calc(0.5rem + 1.3125rem);
    font-size: 1em;
    font-weight: 700;
    border-radius: 2.5rem;
    box-shadow: 0rem 0.5rem 0.5rem 0rem #FFEF56;
    flex-direction: row-reverse;
    justify-content: flex-end;
    transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
}

#buttons02 li a svg {
    display: block;
    fill: #000000;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    min-width: 18px;
    width: 1.25em;
    margin-left: 0.5em;
    margin-right: calc(-0.125em + 0.5rem);
    transition: fill 0.25s ease;
}

#buttons02 li a .label {
    direction: var(--site-language-direction);
    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: left;
    width: 100%;
}

#buttons02 .button {
    background-color: #FFFFFF;
    color: #000000;
}

#buttons02 .button svg {
    fill: #573E3E;
}

#buttons02 .button:hover {
    background-color: #FFEF56 !important;
}

#buttons02 .n01 svg {
    fill: #000000;
}

#buttons02 .n01 {
    background-color: #FFFFFF;
    color: #000000;
}

#buttons01 {
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
}

#buttons01:not(:first-child) {
    margin-top: 2rem !important;
}

#buttons01:not(:last-child) {
    margin-bottom: 2rem !important;
}

#buttons01 li a {
    display: inline-block;
    width: 8.25rem;
    height: 2.625rem;
    line-height: calc(2.625rem - 2px);
    padding: 0 0.5rem;
    vertical-align: middle;
    text-transform: uppercase;
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 0.3rem;
    padding-left: calc(0.3rem + 0.5rem);
    font-size: 0.625em;
    font-weight: 700;
    border-radius: 0.375rem;
    direction: var(--site-language-direction);
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
}

#buttons01 .button {
    color: #FFFFFF;
    border: solid 1px rgba(255, 255, 255, 0.169);
}

#buttons01 .button:hover {
    border-color: #FF8CA8 !important;
    color: #FF8CA8 !important;
}

.icc-credits {
    display: block;
    opacity: 1 !important;
    position: relative;
    transition-delay: 0s !important;
}

.icc-credits span {
    border-radius: 24px;
    cursor: pointer;
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 1;
    position: relative;
    text-decoration: none;
    width: auto;
}

.icc-credits span a {
    display: inline-block;
    padding: 0.5em 0.375em;
    position: relative;
    text-decoration: none;
    transition: color 0.25s ease, transform 0.25s ease;
    z-index: 1;
}

.icc-credits span a:before {
    content: '( ';
    opacity: 1;
    transition: opacity 0.25s ease;
}

.icc-credits span a:after {
    content: ' )';
    opacity: 1;
    transition: opacity 0.25s ease;
}

.icc-credits span::after {
    background-image: linear-gradient(30deg, #A464A1 15%, #3B5DAD 85%);
    border-radius: inherit;
    box-shadow: 0 0.25em 1.25em 0 rgba(0, 0, 0, 0.25);
    content: '';
    display: block;
    height: calc(100% + 2px);
    left: -1px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -1px;
    transition: opacity 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease;
    width: calc(100% + 2px);
}

.icc-credits span:hover {
    text-transform: none !important;
}

.icc-credits span:hover a {
    color: #ffffff !important;
    transform: scale(1.1) translateY(-0.05rem);
}

.icc-credits span:hover a:before {
    opacity: 0;
}

.icc-credits span:hover a:after {
    opacity: 0;
}

.icc-credits span:hover::after {
    opacity: 1;
    transform: scale(1.1) translateY(-0.05rem);
}

#credits span {
    color: rgba(255, 255, 255, 0.498);
    margin-top: 1.5rem !important;
}

@media (max-width: 1920px) {}

@media (max-width: 1680px) {
    html {
        font-size: 13pt;
    }
}

@media (max-width: 1280px) {
    html {
        font-size: 13pt;
    }
}

@media (max-width: 1024px) {}

@media (max-width: 980px) {
    html {
        font-size: 11pt;
    }
}

@media (max-width: 736px) {
    html {
        font-size: 11pt;
    }

    #main>.inner {
        --padding-horizontal: 2rem;
        --padding-vertical: 2.5rem;
        --spacing: 1.5rem;
    }

    #main>.inner>*>.full {
        margin-left: calc(-2rem);
        max-width: calc(100% + 4rem + 0.4725px);
        width: calc(100% + 4rem + 0.4725px);
    }

    #main>.inner>*>.full.screen {
        margin-left: -50vw;
    }

    #main>.inner>footer>.full:last-child {
        margin-bottom: -2.5rem !important;
    }

    #main>.inner>.active>.full:first-child {
        margin-top: -2.5rem !important;
    }

    #image03 img {
        object-fit: cover;
        object-position: center;
    }

    #image02 .frame {
        width: 11rem;
        height: 13.125rem !important;
    }

    #image02 img {
        height: 100% !important;
        object-fit: cover;
        object-position: center;
        width: 100% !important;
    }

    #image01 .frame {
        width: 11rem;
        height: 13.59375rem !important;
    }

    #image01 img {
        height: 100% !important;
        object-fit: cover;
        object-position: center;
        width: 100% !important;
    }

    #gallery01 ul li img {
        height: 6.65625rem;
    }

    #text04 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1.25em;
        line-height: 1.625;
    }

    #text02 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text07 {
        letter-spacing: 0.284375rem;
        width: calc(100% + 0.284375rem);
        font-size: 1.5em;
        line-height: 1.375;
    }

    #text08 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text03 {
        letter-spacing: 0.284375rem;
        width: calc(100% + 0.284375rem);
        font-size: 1.5em;
        line-height: 1.375;
    }

    #text05 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text09 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text10 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text11 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text12 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text13 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text14 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text15 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text16 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #text06 {
        letter-spacing: 0rem;
        width: 100%;
        font-size: 1em;
        line-height: 1.625;
    }

    #footer {
        margin-top: 0rem !important;
    }

    #divider01:not(:first-child) {
        margin-top: 0.5rem !important;
    }

    #divider01:not(:last-child) {
        margin-bottom: 0.5rem !important;
    }

    #divider01:before {
        width: 7rem;
    }

    #buttons02 {
        gap: 1rem;
    }

    #buttons02:not(:first-child) {
        margin-top: 1.5rem !important;
    }

    #buttons02:not(:last-child) {
        margin-bottom: 1.5rem !important;
    }

    #buttons02 li a {
        letter-spacing: 0.5rem;
        padding-left: calc(0.5rem + 1.3125rem);
        font-size: 1em;
    }

    #buttons02 li a svg {
        width: 1.25em;
    }

    #buttons01 {
        gap: 1rem;
    }

    #buttons01:not(:first-child) {
        margin-top: 1.5rem !important;
    }

    #buttons01:not(:last-child) {
        margin-bottom: 1.5rem !important;
    }

    #buttons01 li a {
        letter-spacing: 0.3rem;
        padding-left: calc(0.3rem + 0.5rem);
        font-size: 0.625em;
    }
}

@media (max-width: 480px) {
    #main>.inner {
        --spacing: 1.3125rem;
    }

    #image02 .frame {
        height: 10.5rem !important;
    }

    #image01 .frame {
        height: 10.875rem !important;
    }

    #buttons02 {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    #buttons02 li a {
        max-width: 32rem;
        width: 100%;
    }

    #buttons01 {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    #buttons01 li a {
        max-width: 32rem;
        width: 100%;
    }
}

@media (max-width: 360px) {
    #main>.inner {
        --padding-horizontal: 1.5rem;
        --padding-vertical: 1.875rem;
        --spacing: 1.125rem;
    }

    #main>.inner>*>.full {
        margin-left: calc(-1.5rem);
        max-width: calc(100% + 3rem + 0.4725px);
        width: calc(100% + 3rem + 0.4725px);
    }

    #main>.inner>*>.full.screen {
        margin-left: -50vw;
    }

    #main>.inner>footer>.full:last-child {
        margin-bottom: -1.875rem !important;
    }

    #main>.inner>.active>.full:first-child {
        margin-top: -1.875rem !important;
    }

    #image02 .frame {
        height: 9.1875rem !important;
    }

    #image01 .frame {
        height: 9.515625rem !important;
    }

    #gallery01 ul {
        gap: 0.375rem;
    }

    #text04 {
        font-size: 1.25em;
    }

    #text02 {
        font-size: 1em;
    }

    #text07 {
        font-size: 1.5em;
    }

    #text08 {
        font-size: 1em;
    }

    #text03 {
        font-size: 1.5em;
    }

    #text05 {
        font-size: 1em;
    }

    #text09 {
        font-size: 1em;
    }

    #text10 {
        font-size: 1em;
    }

    #text11 {
        font-size: 1em;
    }

    #text12 {
        font-size: 1em;
    }

    #text13 {
        font-size: 1em;
    }

    #text14 {
        font-size: 1em;
    }

    #text15 {
        font-size: 1em;
    }

    #text16 {
        font-size: 1em;
    }

    #text06 {
        font-size: 1em;
    }

    #buttons02 {
        gap: 0.75rem;
    }

    #buttons01 {
        gap: 0.75rem;
    }
}
    #welcome {
        overflow: auto !important;
    }

    #main {
        opacity: 1.0 !important;
        transform: none !important;
        transition: none !important;
        filter: none !important;
    }

    #main>.inner>section {
        opacity: 1.0 !important;
        transform: none !important;
        transition: none !important;
        filter: none !important;
    }
.card{
    background-image: linear-gradient(45deg, rgb(89, 148, 228), rgb(213, 155, 203)); background-repeat: no-repeat; background-size: cover; box-shadow: rgb(136, 136, 136) 5px 10px 18px; border-radius: 10px; height: 400px; width: 400px; display: flex; justify-content: center; align-items: center; text-align: center; padding: 20px; color: rgb(255, 255, 255); font-size: 1rem; margin-top: 150px; position: relative; user-select: none; touch-action: pan-y;
}